<template>
    <SetupGlobal @pressed="getStarted" buttonText="LET'S GET STARTED">
        <Popup
            v-if="popupBoolean"
            :skipIntroScreen="true"
            @close="popupBoolean = false"
            @done="finished"
            introImage="https://bildhive.nyc3.digitaloceanspaces.com/noinstace/8cd4bacc460e4a4a905b6ced5f04326e.png"
            color="purple"
            :step="steps[currentStep]"
            v-model="step"
            title="Headline goes here"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac erat gravida, tempor magna quis, lacinia ex. Pellentesque ac ex aliquam dui feugiat consequat. Morbi id dolor enim. Mauris dictum ligula"
        >
            <template v-slot:right>
                <component
                    :is="'Step' + currentStep"
                    @next="step++"
                    @done="finished"
                    @load="(is) => (loadPopup = is ? is : !loadPopup)"
                />
            </template>
        </Popup>
    </SetupGlobal>
</template>

<script>
import SetupGlobal from "bh-mod/components/layout/SetupGlobal";
import Popup from "bh-mod/components/layout/SetupGlobal/Popup";
import BhLoading from "bh-mod/components/common/Loading";
import Step0 from "./Step0";
export default {
    components: { SetupGlobal, Popup, Step0, BhLoading },
    data() {
        return {
            loadPopup: false,
            popupBoolean: false,
            currentStep: 0,
            step: 0,
            steps: [
                {
                    title: "Choose your Masterplan image",
                    backButton: true,
                    customLeft: false,
                },
            ],
        };
    },
    watch: {
        step(val) {
            if (this.steps[val]) {
                this.currentStep = val;
            } else {
                this.finished();
            }
        },
    },
    methods: {
        finished() {
            this.$router.push("/");
        },
        getStarted() {
            this.popupBoolean = true;
        },
    },
    created() {},
};
</script>

<style lang="scss">
.amenity-dialog {
    [role="tablist"] {
        display: none;
    }
}
</style>
