<template>
    <div>
        <portal to="destination">
            <!-- <a-button size="large" class="text-black mx-4" type='link' @click="skipIntro">SKIP</a-button> -->
            <a-button :disabled="!this.baseImage.trim()" size="large" type='purple' class="back-button" @click="next" >FINISH</a-button>

        </portal>
        
        <div class="p5">
            <h3>This will appear as your base site plan image</h3>
            <div class="base-image-holder">
                <div class="base-image-box">
                    <p style="color:#9EA0A5">Standard Quality</p>
                    <ImageBox class="mt-3" :img="baseImage" @callback="updateImage" @sized="sizeImage"></ImageBox>
                </div>
                <div class="base-image-box add-margin-top">
                    <p style="color:#9EA0A5">High resolution (for touchscreen and siteplan table)</p>
                    <ImageBox class="mt-3" :img="highRes" @callback="updateHighRes" @sized="sizeHighRes"></ImageBox>
                </div>
            </div>
        </div>
       
    </div>
</template>

<script>

import ImageBox from '@/components/siteplan/ImageBox'

export default {
    components:{ImageBox},
    props:{
        value:Number
    },
    data() {
        return{
            baseImage:'',
            sendImg:{},
            siteplanCreated:false,
            highRes:'',
            sendHighRes:{}
        }
    },
    computed:{
        instance() {
            return this.$store.state.instance
        },
    },
    watch:{
        currentStep(val){
            return this.$emit('input',val)
        },
    
    },
    methods:{
        updateImage(data) {
            this.baseImage = data
            this.sendImg.image = data
        },
        updateHighRes(data) {
            this.highRes = data
            this.sendHighRes.image = data
        },
        sizeImage(data) {
            this.sendImg.width = data.width
            this.sendImg.height = data.height
        },
        sizeHighRes(data) {
            this.sendHighRes.width = data.width
            this.sendHighRes.height = data.height
        },
        next() {
            let width=this.sendImg.width
            let height=this.sendImg.height
            let sendObj = {isMaster:true,name:this.instance.name,instance:this.instance.id, image:this.baseImage, width, height, buildingType:'models'}
            if (this.highRes){
                sendObj.options = {media:{hq:this.highRes, width:this.sendHighRes.width, height:this.sendHighRes.height}}
            }
            this.$api.post(`/siteplans/${this.instance.id}`,sendObj ).then( ({data}) => {
                // this.$api.put(`/siteplans/:instance/${data.id}`, {}).then(({highresData}) => {
                //     console.log('added high res image',highresData )
                // })
                this.$store.dispatch('SET_APPDATA',{masterplan:data})
                this.$emit('next')
            }).catch( err => {
                let error = this.$err(err)
                this.$message.error(error)
            })
        },
        skip(){
            return this.currentStep++
        },
        finish(){
            
            //do something with current Step and then move to the next page
            this.$store.commit('LOAD')
            this.$emit('done')
        },

    },
    created(){
       
    }
}
</script>

<style scoped>
    @media screen and (max-width:1600px) {
    .base-image-box{
            width:100%;
        }
    .add-margin-top{
            margin-top:55px;
        }
    }
    @media screen and (min-width:1600px) {
        .base-image-holder{
            display:flex;
            align-items: center;
        }
        .base-image-box{
            width:50%;
        }
        .add-margin-top{
            margin-top:0;
            margin-left:10px;
        }
    }
</style>